

import React, { useEffect, useState } from 'react';
import toast from '../../../common/ui/Toast';
import { getConfig } from 'utils/functions';
import PageHeader from '../../mini-components/PageHeader';
import SnapScrollContainer from '../../mini-components/SnapScrollContainer';
import ScrollTopBtn from '../../mini-components/ScrollTopBtn';
import { getOverview } from '../../common/ApiCalls';
import { isEmpty } from '../../../utils/validators';
// import PortfolioGrowth from './PortfolioGrowth';
import { genericErrMsg } from '../../common/constants';
import AllocationGraphs from './AllocationGraphs';
import IwdDisclaimerNote from '../../mini-components/IwdDisclaimerNote/IwdDisclaimerNote';
import OverviewCard from './OverviewCard';
import { useUserContext } from '../../common/UserContext';
import { userNameFormatter } from '../../common/commonFunctions';
import { colorConfig } from '../../theme/colorConfig';

const isMobileView = getConfig().isMobileDevice;

const Dashboard = () => {
  const { userDetails, selectedChildUser, isWmLogin } = useUserContext();
  const username = selectedChildUser?.name || userDetails.name;
  const [overviewData, setOverviewData] = useState({});
  const [assetAllocData, setAssetAllocData] = useState({});
  const [productSummaryData, setProductSummaryData] = useState({});
  const [isLoadingOverview, setIsLoadingOverview] = useState(true);
  const [overviewError, setOverviewError] = useState(false);

  const hasAllEmptyProps = (obj) => {
    return Object.entries(obj).every(([, val]) => !val); 
  };

  const fetchOverview = async () => {
    try {
      setIsLoadingOverview(true);
      setOverviewError(false);
      const data = await getOverview();
      const overviewObj = data.aggregated_values;
      const assetAlloc = data.asset_allocation;
      const productSummary = data.product_wise_summary;
      if (hasAllEmptyProps(overviewObj) || hasAllEmptyProps(assetAlloc)) {
        throw (genericErrMsg);
      }
      setOverviewData(overviewObj);
      setAssetAllocData(assetAlloc);
      setProductSummaryData(productSummary);
    } catch (e) {
      setOverviewError(true);
      console.error(e);
      toast(e);
    }
    setIsLoadingOverview(false);
  };

  const fetchAllData = () => {
    fetchOverview();
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const pageChanged = (pageNum) => {
    const page = document.getElementById('iwd-dashboard');
    const header = document.getElementById('iwd-ph-left');

    if (page && !isEmpty(page)) {
      if (pageNum !== 1) {
        page.style.background = colorConfig.supporting.white;
        if (header) header.classList.add('iwd-dashboard-pg1');
      } else {
        page.style.background = colorConfig.primary.gold[400];
        if (header) header.classList.remove('iwd-dashboard-pg1');
      }
    }
  };

  return (
    <div className='iwd-page' id='iwd-dashboard'>
      <PageHeader hideLogout={isWmLogin}>
        <>
          <div className='iwd-header-title brand-font'>Dashboard</div>
          <div className='iwd-header-subtitle'>Welcome back, {userNameFormatter(username)}</div>
        </>
      </PageHeader>
      <SnapScrollContainer
        pages={2}
        hideFooter={isMobileView}
        onPageChange={pageChanged}
        isLoading={isLoadingOverview}
        loadingText='Please wait...getting your investment data'
        error={overviewError}
        onErrorBtnClick={fetchAllData}
      >
        <SnapScrollContainer.Page pageNumber={1}>
          <OverviewCard
            overviewData={overviewData}
            error={isEmpty(overviewData) || overviewError}
            errorText='Something went wrong! Please retry after some time or contact your wealth manager'
            isLoading={isLoadingOverview}
            style={{
              background: isLoadingOverview || isEmpty(overviewData) ? 'white' : '',
            }}
            showInfoIcon={true}
          />
          {assetAllocData &&
            <IwdDisclaimerNote
              className="iwd-animatedFade"
              showNote
              message="Contact your wealth manager for the valuation report of bonds, unlisted shares &amp; insurance"
              style={{ color: colorConfig.content.secondary }}
            />
          }
        </SnapScrollContainer.Page>
        <SnapScrollContainer.Page pageNumber={2}>
          <AllocationGraphs
            productSummary={productSummaryData}
            assetAllocation={assetAllocData}
          />
        </SnapScrollContainer.Page>
        {/* <SnapScrollContainer.Page pageNumber={3}>
          <PortfolioGrowth />
        </SnapScrollContainer.Page> */}
        {isMobileView && <ScrollTopBtn />}
      </SnapScrollContainer>
    </div>
  );
};

export default Dashboard;
